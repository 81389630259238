<template>
  <base-modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal-info-entity">
      <div class="modal__topbar">
        <button class="button modal__close">
          <i class="icon icon-close"></i>
        </button>
      </div>

      <div class="modal__content">
        <div class="modal__header">
          <h2 class="modal__title">{{ this.operation === 'transfer' ? 'Transferir' : 'Resgatar' }}</h2>
        </div>
        <div class="modal__form--group d-flex flex-wrap">
          <money v-model="value" v-bind="money"></money>
          <div class="footer_modal">
            <button :disabled="loading" @click.prevent="register()" type="button" class="button_outline">{{ this.operation === 'transfer' ? 'Transferir' : 'Resgatar' }}</button>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'
import { Money } from 'v-money'

const defaultForm = {
  id: '',
  zip_code: '',
  district: '',
  complement: '',
  street: '',
  city_id: '',
  province_id: '',
  name: '',
  phone: '',
  cpf: '',
  cnpj: '',
  collector_type: '',
  email: '',
  password: '',
  number: ''
}
const defaultLoadedByZip = {
  street: false,
  number: false,
  district: false,
  complement: false
}

export default {
  data: () => ({
    form: { ...defaultForm },
    rules: {},
    loading: false,
    isEditing: false,
    loadedByZip: { ...defaultLoadedByZip },
    isOpen: {
      pessoal: false,
      access: false,
      address: false,
      material: false
    },
    avatarBuffer: '',
    provinces: [],
    cities: [],
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
      masked: false
    },
    value: null
  }),

  props: {
    modal: Boolean,
    editing: {},
    operation: { type: String, default: 'receive' },
    collector: {}
  },

  computed: {
    valid () {
      if (!this.value) return false
      return true
    }
  },

  methods: {
    initForm () {
      this.form = { ...defaultForm }
      this.form.materials = this.materials.map(material => {
        return {
          isChecked: false,
          city_company_item_id: material.id,
          name: material.item,
          city: material.city
        }
      })
      this.loading = false
      this.loadedByZip = { ...defaultLoadedByZip }
      this.isOpen = {
        pessoal: false,
        access: false,
        address: false,
        material: false
      }
      this.avatarBuffer = ''
    },
    async register () {
      if (!this.valid) {
        return swal({
          title: 'Atenção!',
          text: 'Preencha todos os campos',
          icon: 'error',
          button: 'Ok'
        })
      }
      this.loading = true
      try {
        const data = {
          origin: this.operation === 'transfer' ? 'true' : 'false',
          source: this.collector,
          ammount: this.value,
          title: this.operation === 'transfer' ? 'Transferência' : 'Resgate',
          historic: 'acerto'
        }
        await axios.post('/account/transfer', data)
        swal({
          title: 'Sucesso!',
          text: `${data.title} realizado com sucesso.`,
          icon: 'success',
          timer: 4000
        }).then(async () => {
          this.$emit('change-modal', false)
          this.initForm()
        })
      } catch (error) {
        await swal({
          title: 'Ooops!',
          text: 'Verifique os valores informados para transferência',
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
      }
    },
    parseData () {
      const data = new FormData()

      Object.keys(this.form).map(key => {
        const item = this.form[key]
        if (Array.isArray(item)) {
          let count = 0
          item.map(subitem => {
            Object.keys(subitem).map(ik => {
              data.append(`${key}[${count}][${ik}]`, subitem[ik])
            })
            count++
          })
        } else {
          data.append(key, item)
        }
      })
      return data
    },
    async getProvinces () {
      const response = await axios.get('/provinces')
      this.provinces = response.data
    },
    async getCities () {
      const response = await axios.get('cities?province_id=' + this.form.province_id)
      this.cities = response.data
    }
  },

  components: {
    baseModal: () => import('@/components/modal'),
    Money
  },

  async created () {
    await this.getProvinces()
  },

  watch: {
    async 'form.zip_code' (val) {
      if (!val || val.length < 8 || this.isEditing) return
      const response = await axios.get('/cep?cep=' + val)
      this.form.zip_code = response.data.cep
      this.form.district = response.data.bairro
      this.form.complement = response.data.complemento
      this.form.street = response.data.logradouro
      this.form.number = response.data.numero
      this.form.province_id = response.data.province_id
      this.form.city_id = response.data.city_id
    },
    editing (val) {
      if (!Object.keys(val).length) return null
      this.isEditing = true
      this.form = val

      setTimeout(() => {
        this.isEditing = false
      }, 200)
    },
    async 'form.province_id' () {
      await this.getCities()
    }
  }
}
</script>

<style lang="css">
.subtitle_form {
  position: relative;
  width: 100%;
}
.modal-chevron {
  color: #05b446;
  position: absolute;
  right: 10px;
  font-size: 22px;
  top: 50%;
  transition: 0.2s;
  transform: translateY(-50%) rotate(360deg);
}
.modal-chevron.open {
  transform: translateY(-50%) rotate(180deg);
}
.cursor-pointer {
  cursor: pointer;
}
</style>
